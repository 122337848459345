.code-input {
  display: flex;
  flex-direction: column;
  align-items: start;
}
.code-label {
  margin-bottom: 16px;
}
.code-inputs {
  display: flex;
  justify-content: start;
  align-items: center;
}
.code-inputs input {
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-align: center;
  height: 45px;
  width: 45px;
  border-radius: 10px;
  margin: 0 4px;
  border: 2px solid #4f5b66;
  font-size: 24px;
}
.code-inputs input:focus {
  outline: none;
  border: 2px solid turquoise;
}
