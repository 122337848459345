@import url("https://fonts.googleapis.com/css?family=Inconsolata&display=swap");
@import url("https://fonts.googleapis.com/css?family=Karla&display=swap");

.FeaturesSection__subtitle {
  max-width: 700px;
  margin: 0 auto;
}

.Features {
  max-width: 900px;
  margin: 80px auto 0 auto;
}
.Features__columns:nth-child(even) {
  flex-direction: row-reverse;
}
.Features__columns:not(:last-of-type) {
  padding-bottom: 1.5rem;
}
@media screen and (min-width: 769px) {
  .Features__columns:not(:last-of-type) {
    padding-bottom: 2.5rem;
  }
}
.Features__title {
  margin-bottom: 1.2rem !important;
}
.Features__no-classname {
  display: block;
  max-width: 300px;
  margin: 30px auto;
}

.tabs li.is-active a {
  border-bottom: 1px solid turquoise !important;
  font-weight: bolder;
}

.tabs li a:hover {
  border-bottom: 1px solid turquoise !important;
  color: turquoise !important;
  opacity: 0.8;
}

body,
.dark-primary {
  background-color: #0c0c0c;
}

.dark-secondary {
  background-color: #1a1a1a;
}

.dark-dark {
  background-color: #1f2933;
}


.dark-text {
  color: white;
}

.nft {
  user-select: none;
  max-width: 300px;
  border: 1px solid #ffffff;
  background-color: #060607;
  background: linear-gradient(0deg, #282c34 0%, rgba(5, 0, 10, 0.5) 100%);
  box-shadow: 0 7px 20px 5px #00000088;
  border-radius: 0.7rem;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  overflow: hidden;
  transition: 0.5s all;
}
.nft hr {
  width: 100%;
  border: none;
  border-bottom: 1px solid #88888855;
  margin-top: 0;
}
.nft ins {
  text-decoration: none;
}
.nft .main {
  display: flex;
  flex-direction: column;
  width: 90%;
  padding: 1rem;
}
.nft .main .tokenImage {
  border-radius: 0.5rem;
  max-width: 100%;
  height: 250px;
  object-fit: cover;
}
.nft .main .description {
  margin: 0.5rem 0;
  color: #a89ec9;
}
.nft .main .tokenInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nft .main .tokenInfo .price {
  display: flex;
  align-items: center;
  color: #ee83e5;
  font-weight: 700;
}
.nft .main .tokenInfo .price ins {
  margin-left: -0.3rem;
  margin-right: 0.5rem;
}
.nft .main .tokenInfo .duration {
  display: flex;
  align-items: center;
  color: #a89ec9;
  margin-right: 0.2rem;
}
.nft .main .tokenInfo .duration ins {
  margin: 0.5rem;
  margin-bottom: 0.4rem;
}
.nft .main .creator {
  display: flex;
  align-items: center;
  margin-top: 0.2rem;
  margin-bottom: -0.3rem;
}
.nft .main .creator ins {
  color: #a89ec9;
  text-decoration: none;
}
.nft .main .creator .wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #ffffff22;
  padding: 0.3rem;
  margin: 0;
  margin-right: 0.5rem;
  border-radius: 100%;
  box-shadow: inset 0 0 0 4px #000000aa;
}
.nft .main .creator .wrapper img {
  border-radius: 100%;
  border: 1px solid #ffffff22;
  width: 2rem;
  height: 2rem;
  object-fit: cover;
  margin: 0;
}
.nft ::before {
  position: fixed;
  content: "";
  box-shadow: 0 0 100px 40px #ffffff08;
  top: -10%;
  left: -100%;
  transform: rotate(-45deg);
  height: 60rem;
  transition: 0.7s all;
}
.nft:hover {
  border: 1px solid #ffffff44;
  box-shadow: 0 7px 50px 10px #000000aa;
  transform: scale(1.015);
  filter: brightness(1.1);
}
.nft:hover ::before {
  filter: brightness(0.5);
  top: -100%;
  left: 200%;
}
.bg {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.bg h1 {
  font-size: 20rem;
  filter: opacity(0.5);
}

::-webkit-input-placeholder {
  color: #a3a3a3 !important;
}

.animate-pulse {
  animation: spin 1s linear infinite;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.portfolio-experiment,
.scene {
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-display: flex;
  -webkit-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  -webkit-justify-content: center;
  -webkit-align-items: center;
}

.portfolio-experiment {
  background: #00ffa5;
  color: #203e86;
}
.portfolio-experiment .experiment-title {
  outline: none;
  font-size: 10vw;
  text-align: center;
  font-family: "Roboto", monospace;
  min-height: 12vw;
  max-width: 80vw;
  min-width: 10vw;
}
.experiment-title,
.experiment-title * {
  -webkit-animation: cray 10s infinite steps(50);
  animation: cray 10s infinite steps(50);
  display: inline-block;
}
@-webkit-keyframes cray {
  2% {
    font-weight: 400;
    font-style: italic;
    text-decoration: none;
    text-transform: none;
  }
  4% {
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    text-transform: lowercase;
  }
  6% {
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  8% {
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    text-transform: uppercase;
  }
  10% {
    font-weight: 300;
    font-style: italic;
    text-decoration: none;
    text-transform: lowercase;
  }
  12% {
    font-weight: 500;
    font-style: normal;
    text-decoration: none;
    text-transform: lowercase;
  }
  14% {
    font-weight: 100;
    font-style: normal;
    text-decoration: line-through;
    text-transform: none;
  }
  16% {
    font-weight: 300;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  18% {
    font-weight: 500;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  20% {
    font-weight: 700;
    font-style: normal;
    text-decoration: none;
    text-transform: uppercase;
  }
  22% {
    font-weight: 300;
    font-style: normal;
    text-decoration: underline;
    text-transform: none;
  }
  24% {
    font-weight: 300;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  26% {
    font-weight: 600;
    font-style: italic;
    text-decoration: none;
    text-transform: none;
  }
  28% {
    font-weight: 300;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  30% {
    font-weight: 300;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  32% {
    font-weight: 300;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  34% {
    font-weight: 700;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  36% {
    font-weight: 700;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  38% {
    font-weight: 100;
    font-style: italic;
    text-decoration: none;
    text-transform: none;
  }
  40% {
    font-weight: 200;
    font-style: italic;
    text-decoration: none;
    text-transform: none;
  }
  42% {
    font-weight: 100;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  44% {
    font-weight: 600;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  46% {
    font-weight: 300;
    font-style: normal;
    text-decoration: none;
    text-transform: uppercase;
  }
  48% {
    font-weight: 300;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  50% {
    font-weight: 100;
    font-style: normal;
    text-decoration: underline;
    text-transform: none;
  }
  52% {
    font-weight: 500;
    font-style: italic;
    text-decoration: none;
    text-transform: none;
  }
  54% {
    font-weight: 700;
    font-style: normal;
    text-decoration: none;
    text-transform: uppercase;
  }
  56% {
    font-weight: 200;
    font-style: normal;
    text-decoration: line-through;
    text-transform: none;
  }
  58% {
    font-weight: 500;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  60% {
    font-weight: 100;
    font-style: italic;
    text-decoration: none;
    text-transform: none;
  }
  62% {
    font-weight: 500;
    font-style: normal;
    text-decoration: none;
    text-transform: uppercase;
  }
  64% {
    font-weight: 300;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  66% {
    font-weight: 700;
    font-style: normal;
    text-decoration: none;
    text-transform: uppercase;
  }
  68% {
    font-weight: 600;
    font-style: italic;
    text-decoration: none;
    text-transform: uppercase;
  }
  70% {
    font-weight: 700;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  72% {
    font-weight: 200;
    font-style: italic;
    text-decoration: none;
    text-transform: none;
  }
  74% {
    font-weight: 600;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  76% {
    font-weight: 400;
    font-style: italic;
    text-decoration: none;
    text-transform: none;
  }
  78% {
    font-weight: 500;
    font-style: normal;
    text-decoration: line-through;
    text-transform: none;
  }
  80% {
    font-weight: 600;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  82% {
    font-weight: 500;
    font-style: italic;
    text-decoration: underline;
    text-transform: uppercase;
  }
  84% {
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  86% {
    font-weight: 700;
    font-style: italic;
    text-decoration: line-through;
    text-transform: none;
  }
  88% {
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  90% {
    font-weight: 600;
    font-style: normal;
    text-decoration: none;
    text-transform: uppercase;
  }
  92% {
    font-weight: 700;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  94% {
    font-weight: 200;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  96% {
    font-weight: 600;
    font-style: normal;
    text-decoration: underline;
    text-transform: none;
  }
  98% {
    font-weight: 300;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
}
@keyframes cray {
  2% {
    font-weight: 400;
    font-style: italic;
    text-decoration: none;
    text-transform: none;
  }
  4% {
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    text-transform: lowercase;
  }
  6% {
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  8% {
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  10% {
    font-weight: 300;
    font-style: italic;
    text-decoration: none;
    text-transform: lowercase;
  }
  12% {
    font-weight: 500;
    font-style: normal;
    text-decoration: line-through;
    text-transform: lowercase;
  }
  14% {
    font-weight: 100;
    font-style: normal;
    text-decoration: line-through;
    text-transform: none;
  }
  16% {
    font-weight: 300;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  18% {
    font-weight: 500;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  20% {
    font-weight: 700;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  22% {
    font-weight: 300;
    font-style: normal;
    text-decoration: underline;
    text-transform: none;
  }
  24% {
    font-weight: 300;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  26% {
    font-weight: 600;
    font-style: italic;
    text-decoration: none;
    text-transform: none;
  }
  28% {
    font-weight: 300;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  30% {
    font-weight: 300;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  32% {
    font-weight: 300;
    font-style: normal;
    text-decoration: none;
    text-transform: uppercase;
  }
  34% {
    font-weight: 700;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  36% {
    font-weight: 700;
    font-style: normal;
    text-decoration: line-through;
    text-transform: none;
  }
  38% {
    font-weight: 100;
    font-style: italic;
    text-decoration: none;
    text-transform: none;
  }
  40% {
    font-weight: 200;
    font-style: italic;
    text-decoration: none;
    text-transform: none;
  }
  42% {
    font-weight: 100;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  44% {
    font-weight: 600;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  46% {
    font-weight: 300;
    font-style: normal;
    text-decoration: none;
    text-transform: uppercase;
  }
  48% {
    font-weight: 300;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  50% {
    font-weight: 100;
    font-style: normal;
    text-decoration: underline;
    text-transform: none;
  }
  52% {
    font-weight: 500;
    font-style: italic;
    text-decoration: line-through;
    text-transform: none;
  }
  54% {
    font-weight: 700;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  56% {
    font-weight: 200;
    font-style: normal;
    text-decoration: line-through;
    text-transform: none;
  }
  58% {
    font-weight: 500;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  60% {
    font-weight: 100;
    font-style: italic;
    text-decoration: none;
    text-transform: none;
  }
  62% {
    font-weight: 500;
    font-style: normal;
    text-decoration: none;
    text-transform: uppercase;
  }
  64% {
    font-weight: 300;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  66% {
    font-weight: 700;
    font-style: normal;
    text-decoration: none;
    text-transform: uppercase;
  }
  68% {
    font-weight: 600;
    font-style: italic;
    text-decoration: line-through;
    text-transform: none;
  }
  70% {
    font-weight: 700;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  72% {
    font-weight: 200;
    font-style: italic;
    text-decoration: none;
    text-transform: none;
  }
  74% {
    font-weight: 600;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  76% {
    font-weight: 400;
    font-style: italic;
    text-decoration: none;
    text-transform: none;
  }
  78% {
    font-weight: 500;
    font-style: normal;
    text-decoration: line-through;
    text-transform: none;
  }
  80% {
    font-weight: 600;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  82% {
    font-weight: 500;
    font-style: italic;
    text-decoration: underline;
    text-transform: uppercase;
  }
  84% {
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  86% {
    font-weight: 700;
    font-style: italic;
    text-decoration: line-through;
    text-transform: none;
  }
  88% {
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  90% {
    font-weight: 600;
    font-style: normal;
    text-decoration: none;
    text-transform: uppercase;
  }
  92% {
    font-weight: 700;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  94% {
    font-weight: 200;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  96% {
    font-weight: 600;
    font-style: normal;
    text-decoration: underline;
    text-transform: none;
  }
  98% {
    font-weight: 300;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
}

.flex {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex > div {
  margin: 0px 10px;
  flex: 1 1 auto;
}

body {
  font-family: Inconsolata !important;
  overflow-x: hidden;
}

.navbar-item:hover {
  background-color: rgb(27, 27, 27) !important;
}

.hovah:hover {
  background-color: rgb(27, 27, 27) !important;
}

.navbar-item:visited {
  background-color: rgb(27, 27, 27) !important;
}

.navbar-item:focus {
  background-color: rgb(27, 27, 27) !important;
}

.hoverbrighten:hover {
  filter: brightness(1.1);
}

.no:hover {
  background-color: transparent !important;
}

.letterspacing {
  letter-spacing: 3px !important;
}

.modal-card {
  overflow: auto !important;
}

.PhoneInputInput {
  background-color: transparent !important;
}

.cardone:nth-child(1) { display: none; }


.outer-circle {
  background: linear-gradient(#14ffe9, #ffeb3b, #ff00e0);
  border-radius: 6px;
  animation: rotate 1.5s linear infinite;
}

.inner-circle {
  height: 180px;
  width: 180px;
  position: absolute;
  background: black;
  top: 10px;
  left: 10px;
  border-radius: 50%;
  z-index: 9;
}

@keyframes rotate {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}

@media (max-width: 390px) {
  .mobile-only {
    display: none;
  }
}

@media (max-width: 800px) {
  .mobile-adjust {
    margin-top: -50px !important;
  }
}

@media (max-width: 800px) {
  .mobile-padding {
    padding: 0px 20px !important;
  }
}

@media (max-width: 800px) {
  .mobile-space {
    margin-top: 25px !important;
  }
}

@media (max-width: 800px) {
  .mobile-100-percent {
    width: 100% !important;
  }
}

@media (min-width: 1200px) {
  .add-padding-non-mobile {
    margin-right: 30px !important;
  }
  .desktoppy {
    margin-top: -40px !important;
  }
  .minh {
    min-height: 1000px !important;
  }
}

@media (min-width: 1200px) {
  .remove-ml {
    margin-left: -30px !important;
    margin-right: 50px !important;
  }
}

@media (max-width: 500px) {
  .mobile-gone {
    display: none !important;
  }
}

@media (min-width: 770px) {
  .extra-margin {
    margin-top: -200px !important;
  }
  .cover-height {
    height: 326px !important;
  }
}
@media (max-width: 1020px) {
  .mobile-goner {
    display: none !important;
  }
}

@media (max-width: 800px) {
  .mobile-search-goner {
    display: none !important;
  }
}

@media (max-width: 769px) {
  .cover-height {
    height: 180px !important;
  }
}


@media (min-width: 1021px) {
  .desktop-goner {
    display: none !important;
  }
}

pre {
  background-color: rgb(68, 68, 68) !important;
  color: turquoise !important;
}

.hov:hover {
  background-color: turquoise;
  color:black;
}

.blur-r::after {
  content: '';
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 60px;
  height: 220px;
  right: 0;
  position: absolute;
  z-index: 9999999;
  margin-left: auto;
  bottom: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0, rgba(0, 0, 0, 0.7) 40%, rgb(0, 0, 0) 70%);
}

.blur {
  width:150px;
  height:150px;
  background-color:black;
  border-radius:15px;
  border-style:solid;
  border-width:1px;
  border-color:red;
  box-shadow: 0px 0px 15px rgba(255,0,0);
}

.right {
  margin-right: 0;
  margin-left: auto;
  display: block;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -15px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 15px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 25px;
}

.tooltip-wrapper {
  display: inline-block;
  position: relative;
  width: fit-content;
}
.tooltip-wrapper .icon {
  cursor: pointer;
}
.tooltip-wrapper .description {
  display: none;
  text-align: center;
  padding: 1px 2px;
  border-radius: 5px;
  position: absolute;
  max-width: 300px;
  padding: 4px 4px;
  word-wrap: nowrap;
  border: 1px solid turquoise;
  background-color: turquoise;
  color: black;
  word-wrap: break-word;
}
.tooltip-wrapper .description::before {
  position: absolute;
  content: "";
  height: 0;
  width: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #19d8b8;
  transform: rotate(-90deg);
}
.tooltip-wrapper:hover .description {
  display: block;
}
.right .description {
  top: 7px;
  margin-left: 110%;
}
.right .description::before {
  left: -12px;
  top: 10px;
  transform: rotate(-90deg);
}
.bottom .description {
  margin-top: 10%;
  left: 30%;
}
.bottom .description::before {
  transform: rotate(0);
  margin-top: -8%;
}
.top .description {
  bottom: 110%;
  margin-left: 30%;
}
.top .description::before {
  transform: rotate(180deg);
  bottom: -8px;
}
.left .description {
  right: 0;
  margin-right: 110%;
  top: 0;
}
.left .description::before {
  transform: rotate(90deg);
  right: 0;
  margin-right: -10px;
  margin-top: 20%;
}

.navbar-item {
  display: flex;
}

.navbar,
.navbar-menu,
.navbar-start,
.navbar-end {
  align-items: stretch;
  display: flex;
  padding: 0;
}

.navbar-menu {
  flex-grow: 1;
  flex-shrink: 0;
}
.navbar-start {
  justify-content: flex-start;
  margin-right: auto;
}
.navbar-end {
  justify-content: flex-end;
  margin-left: auto;
}

.material {
  box-shadow: blue 0px 0px 0px 2px inset, rgb(255, 255, 255) 10px -10px 0px -3px,
    rgb(31, 193, 27) 10px -10px, rgb(255, 255, 255) 20px -20px 0px -3px,
    rgb(255, 217, 19) 20px -20px, rgb(255, 255, 255) 30px -30px 0px -3px,
    rgb(255, 156, 85) 30px -30px, rgb(255, 255, 255) 40px -40px 0px -3px,
    rgb(255, 85, 85) 40px -40px;
}

.boxy {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
}

.scrolly {
  flex-basis: 80%;
  height: 100vh;
  overflow: auto;
}

.button,
button {
  font-family: Inconsolata !important;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  margin-right: 50px !important;
  border: 4px solid rgb(45, 219, 176);
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

nav ul li {
  position:relative;
}

.react-search-box-dropdown{
  position:absolute;
  top:100%;
  left:0;
  right:0;
  z-index:999999;
}

.search-expand {
  max-width: 120px;
}
.hNfsmZ
 { background-color: #1a1a1a}

.hNfsmZ:hover
 { background-color: #1a1a1a}

.search-expand:hover {
  min-width: 720px !important;
}

.navbar-item:hover {
  background-color: transparent !important;
}

.ShzX > .wrapper {
  position: relative !important;
  border-radius: 4px 0px 0px 4px !important;
}

.ifFeZR {
  width: 380px !important
}