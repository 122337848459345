.summary-container {
    max-width: 75%;
    margin: auto;
  }
  
  .summary-container p {
    font-size: 32px;
  }
  
  .summary-container p span {
    font-weight: bold;
  }
  
  .post-summary-container {
    /* just here to help push content around (: */
  }
  
  .next-quiz-button {
    font-size: 20px;
    border: none;
    background: black;
    color: white;
    text-align: left;
    padding: 15px 40px;
    align-content: center;
    vertical-align: middle;
  }
  
  .next-quiz-button:hover {
    cursor: pointer;
  }
  
  @media screen and (max-width: 768px) {
    .summary-container p {
      font-size: 28px;
    }
  }
  