.progression-bar-container {
    width: 50%;
    height: 40px;
    border: 2px solid black;
    margin: 1rem auto 0 auto;
    display: flex;
  }
  
  .fill-red span,
  .fill-green span {
    height: 100%;
    display: inline-flex;
    align-items: center;
  }
  
  @media screen and (max-width: 768px) {
    .progression-bar-container {
      width: calc(100% - 4px);
    }
  }
  