.question-container {
    max-width: 75%;
    margin: auto;
  }
  
  .question {
    font-family: Verdana, Tahoma, sans-serif;
    font-size: 32px;
  }
  
  .answer-list {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 0;
    list-style: upper-alpha;
    list-style-position: inside;
  }
  
  .answer-button {
    font-size: 24px;
    border: none;
    background: none;
  }
  
  .answer-button:focus {
    outline: none;
  }
  
  .answer-button:hover {
    cursor: pointer;
  }
  
  .answer-button:disabled {
    color: black;
    cursor: default;
  }
  
  .answer {
    background: none;
    text-align: left;
    padding: 5px 10px;
    margin: 5px 10px;
    align-content: center;
    vertical-align: middle;
    pointer-events: none;
  }
  
  .answer span {
    font-family: 'Courier New', Courier, monospace;
  }
  
  .post-question-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 120px;
  }
  
  .selected-message {
    font-size: 20px;
    font-style: italic;
  }
  
  .next-question-button {
    font-size: 20px;
    border: none;
    background: black;
    color: white;
    text-align: left;
    padding: 15px 40px;
    align-content: center;
    vertical-align: middle;
  }
  
  .next-question-button:hover {
    cursor: pointer;
  }
  
  @media screen and (max-width: 768px) {
    .question-container {
      max-width: 100%;
      margin: auto;
    }
  
    .answer-button {
      font-size: 20px;
    }
  
    .question {
      font-size: 28px;
      padding: 0 30px;
    }
  
    .answer-list {
      max-width: 100%;
    }
  
    .answer {
      margin: 5px;
    }
  }
  